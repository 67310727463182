
    .modal-background{
        z-index: 2;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
    }
.preloader{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 8px;

}
.transaction-outer{
    width: 100%;
    padding: 1rem;
}
.trans-header{
    padding: 0.5rem;
}
.trans-filter{
    position: relative;
    padding: 1rem;
    display: flex;
    gap: 20px;
}
.calender-background{
    z-index: 4;
}
.trans-calender{
    background: linear-gradient(-135deg,  #e75272 0%, #B11226 100%);
    border: none;
    border-radius: 5px;
    transition: ease-in 0.9s;
}
.trans-calender:hover, .trans-download select:hover{
    background: linear-gradient(-135deg,  #B11226 0%,  #e75272 100%);
    transition: ease-out 0.9s;
}
.trans-calender p{
    padding: 0.5rem;
    margin: 0;
    color: white;
}
.trans-status select, .trans-download{
    background: linear-gradient(-135deg, #e75272 0%, #B11226 100%);
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    color: white;
    position: relative;
}
.trans-download button{
    color: white;
    background-color: transparent;
    border: none;
}
.trans-download select option{
    color: black;
}
.download-dropdown{
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 4;
    padding: 1rem;
    background-color: white;
}
.file-type p{
    
    font-size: 1.2rem;
    font-weight: 500;
    color: black;

}