.step-outer{
    display: flex;
    align-items: center;
}
.step-number{
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #D1D5DB;
}
.step-number p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 18px;
    padding: 0;
    margin: 0;
    letter-spacing: 1.40676px;
    text-transform: uppercase;
    color: #202020;
}
.step-number svg{
    color: white;
}
.step-name{
    padding-left: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7375rem;
    line-height: 27px;
    letter-spacing: 0.112541px;
    color: rgba(32, 32, 32, 0.8);
}
.step-number.completed{
    background-color: #B11226;
}
.step-name.completed{
    color: #B11226;
}
.formtext{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size:0.75rem;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(32, 32, 32, 0.8);
}
.selectfile{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border: 1px solid #707070;
    border-radius: 4px;
}
.selectfile p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 30px;
    color: #979797;
}
.file-warning{
    margin-top: 24px;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 21px;
    color: #000000;
}
.select-filename{
    width: fit-content;
    font-size: 0.4rem;
}
.select-filename span{
    color: #04a9f5;
    margin-right: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.675rem;
}
.businessHead{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
}
.businessHead-2{
    margin-top: 24px;
}
.addimage{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 35px;
    letter-spacing: 0.144384px;
    color: #202020;
}
.files{
    width: 100%;
    margin-top: 11px;
    display: flex;
    align-items: center;
}
.filedisplay{
    display: flex;
    align-items: center;
    padding-left: 0.8rem;
    width: 60%;
    border: 1.2694px solid #D1D5DB;
    border-radius: 10.1552px 0px 0px 10.1552px;
}
.filedisplay p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.10625rem;
    letter-spacing: 0.31735px;
    color: #202020;
}
.filechose{
    display: flex;
    align-items: center;
    padding-left: 0.8rem;
    width: 40%;
    border: 1.2694px solid #E24E61;
    border-radius: 0px 10.1552px 10.1552px 0px;
}
.filechose p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.10625rem;
    text-align: center;
    letter-spacing: 1.58675px;
    text-transform: uppercase;
    color: #E24E61;
}
/* .filedisplay, .filechose{
    padding: 1.2rem 0.8rem;
    height: 70px;
} */
.warning svg{
    margin-right: 8px;
}
.warning{
    margin-top: 11px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.0625rem;
    line-height: 23px;
    letter-spacing: 0.577534px;
    color: rgba(32, 32, 32, 0.8);
}
.payout{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payout-icon{
    background-color: #04a9f5;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.payout-icon svg{
    color: white;
    font-size: 3rem;
}
.payout p{
    margin-top: 55px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    color: #2B2B2B;
}
.submit-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
@media (max-width: 1000px){
    .step-number{
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }
    .step-number p{
        font-size: 0.4125rem;
    }
    .step-number svg{
        font-size: 0.4125rem;
    }
    .step-name{
        line-height: 10px;
        font-size: 0.4125rem;
    }
    .select-filename span{
        color: #B11226;
        font-size: 0.4rem;
    }
    .select-filename{
        width: fit-content;
        font-size: 0.6rem;
    }
    .formtext{
        line-height: 10px;
        font-size:0.5rem;
    }
    .file-warning{
        text-align: left;
        font-size: 0.5rem;
        line-height: 10px;
        color: #000000;
    }
    .businessHead{
        font-size: 0.75rem;
        line-height: 12px;
    }
    .filedisplay p{
        font-size: 0.6125rem;
        line-height: 13px;
    }
    .filechose p{
        font-size: 0.5125rem;
        line-height: 13px;
    }
    .filedisplay, .filechose{
        height: 40px;
    }
    .warning{
        font-size: 0.6025rem;
        line-height: 13px;
    }
    .payout p{
        font-size: 1.0625rem;
        color: #2B2B2B;
    }
}