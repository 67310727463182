.dropzone{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dropzone p{
    text-align: center;
}

.file-select{
    background-color: transparent;
    border: none;
    color: lightblue;
}
.uploads{
    
}
.uploads ul{
    width: 100%;
    padding: 0.5rem;
    background-color: whitesmoke;
    border: black;
    list-style: none;
}
.cancle-select{
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
    border: none;
    width: 100%;
    border-radius: 5px;
}
